@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
html,body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
      min-height: 100vh
  }
  ::-webkit-scrollbar {
      width: 5px;
      background: #F2F0F9
  }
  
  ::-webkit-scrollbar-thumb {
      background: #6E6893;
  }
  
  ::-webkit-scrollbar:horizontal {
      height: 5px;
      background: #F2F0F9
  }
  
  ::-webkit-scrollbar-thumb:horizontal {
      background: #6E6893;
  }
  
  
  /* Track */
  
  ::-webkit-scrollbar-track {
      border-radius: 10px;
  }
  
  a {
      text-decoration: none !important;
      outline: none !important;
      color: #6E6893;
  }
  
  a:active:link:focus {
      text-decoration: none;
      color: currentColor
  }
  
  .login {
      background-image: url(/static/media/fondoInicio.a7f19d04.svg);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
  }
  
  .openFondo {
      -webkit-animation: openFondo .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
              animation: openFondo .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
  }
  
  .slideIn {
      -webkit-animation: slideIn .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
              animation: slideIn .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
  }
  
  .CloseModal {
      -webkit-animation: CloseModal .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
              animation: CloseModal .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
  }
  
  .CloseModalFondo {
      -webkit-animation: CloseModalFondo .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
              animation: CloseModalFondo .5s cubic-bezier(0.22, 1, 0.36, 1) 0s 1 normal none;
  }
  
  @-webkit-keyframes slideIn {
      0% {
          -webkit-transform: translateX(3rem);
                  transform: translateX(3rem);
          opacity: 0;
      }
      100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
      }
  }
  
  @keyframes slideIn {
      0% {
          -webkit-transform: translateX(3rem);
                  transform: translateX(3rem);
          opacity: 0;
      }
      100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
      }
  }
  
  @-webkit-keyframes CloseModal {
      100% {
          -webkit-transform: translateX(3rem);
                  transform: translateX(3rem);
          opacity: 0;
      }
      0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
      }
  }
  
  @keyframes CloseModal {
      100% {
          -webkit-transform: translateX(3rem);
                  transform: translateX(3rem);
          opacity: 0;
      }
      0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
      }
  }
  
  @-webkit-keyframes openFondo {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
  @keyframes openFondo {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
  
  @-webkit-keyframes CloseModalFondo {
      100% {
          opacity: 0;
      }
      0% {
          opacity: 1;
      }
  }
  
  @keyframes CloseModalFondo {
      100% {
          opacity: 0;
      }
      0% {
          opacity: 1;
      }
  }
